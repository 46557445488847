import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/ArticleLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://www.amazon.com/Phoenix-Project-DevOps-Helping-Business/dp/0988262592"
      }}>{`The Phoenix Project`}</a>{`
is a narrative about an organization struggling to evolve to compete in the
digital economy. It was written for a general, business-oriented audience to
help a few vital concepts sink in at all layers of an organization.`}</p>
    <p>{`The book uses the lens of a factory to transfer the lessons of the industrial
revolution &
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Lean_manufacturing"
      }}>{`lean manufacturing`}</a>{` to the
digital era.`}</p>
    <h2>{`The Three Ways`}</h2>
    <h3>{`Flow Efficiency Over Resource Efficiency`}</h3>
    <p>{`Thinking of your business as a manufacturing line where value flows from
business needs to the customer can help you reduce bottlenecks and misaligned
prioritization between organizations.`}</p>
    <p>{`Flow efficiency is optimizing for an end to end value delivery. How fast are you
able to get work from ideation to end-user value? While resource efficiency is
thinking in terms of how utilized your current resources are. It seems the human
default is to think in terms of resource efficiency. "If I'm busy & working my
hardest and so is everyone else... we must be doing all that we can to reach our
desired result!"`}</p>
    <p>{`The insight of optimizing for flow efficiency is that it teaches you to think in
terms of the entire system.`}</p>
    <p>{`Interestingly, this concept is not foreign to engineers. We all know that your
app is only as fast as your slowest bottleneck. Sure, your code may be efficient
but as long as your connecting to that old legacy database and running that
expensive query every request, your ship is sunk. And you're not going to make
it faster by ensuring your CPU & memory usage are always peaked (resource
efficiency). In fact, throwing more machines might ultimately cause more
coordination overhead and flood the constrained resource making matters worse.`}</p>
    <p>{`In the world of software & machines, this concept is intuitive. If you zoom out
a little... to the software makers. You'll find this lens is useful in
optimizing how you make the software itself. Optimize for flow efficiency, end
to end value delivery.`}</p>
    <ul>
      <li parentName="ul">{`Remove constraints in your system`}</li>
      <li parentName="ul">{`Reduce work in progress (it creates coordination & context switching overhead)`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`Another lens is thinking of your business as a flywheel, if any part of the
flywheel isn't forwarding the momentum the whole thing comes to a screeching
halt! This metaphor is useful over the assembly line because it shows that
outputs of the business feedback in as inputs to the business. Farnam Street
had a wonderful podcast with
`}<a parentName="p" {...{
          "href": "https://fs.blog/jim-collins/"
        }}>{`Jim Collins, Keeping the Flywheel in Motion`}</a>{` on
this subject.`}</p>
    </blockquote>
    <h3>{`Shorten Feedback Loops`}</h3>
    <p>{`Flow efficiency teaches us to optimize for delivering value from the business to
the user. The second insight is to shorten feedback loops from the user to
business. This sort of thinking was popularized by the
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Lean_startup"
      }}>{`Lean Startup methodology`}</a>{`.`}</p>
    <p>{`Mechanisms for shortening feedback loops are sometimes battle-tested best
practices. At other times they look more like creative & novel experiments. If
we think of our system fractally, zooming in to our constraints & subsystems we
can employ different strategies based on the culprit.`}</p>
    <p>{`For example, we can drastically shorten feedback loops about our business idea
by deferring the expensive cost of building an entire project and first
conducting market research. Perhaps offering preorders or a marketing site that
gathers emails.`}</p>
    <p>{`We can shorten the feedback loop of specific user experience decisions by first
creating high fidelity prototypes and testing them before paying expensive
development costs.`}</p>
    <p>{`Within the product development subsystem itself, we do all sorts of things to
shorten feedback loops. Here are some of my favorites, listed in order of
optimizing feedback loops from the individual developer experience out to the
broader team.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://prettier.io/"
        }}>{`Editor integrated automated code formatting`}</a>{` that
reveals syntax errors I would have otherwise waited for the linter or compiler
to reveal.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://eslint.org/"
        }}>{`Linter`}</a>{` to reveal syntax and other statically analyzable
errors I would have otherwise waited till runtime to discover.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://flow.org/"
        }}>{`Type System`}</a>{` or a
`}<a parentName="li" {...{
          "href": "https://www.typescriptlang.org/"
        }}>{`Typed Language`}</a>{` to reveal statically
analyzable semantic errors I would have otherwise waited till runtime or
integration time to discover.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://jestjs.io/"
        }}>{`Write tests`}</a>{` that give you immediate feedback as you
author your code you otherwise would need to exercise by hand.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.cypress.io/"
        }}>{`Write end to end tests`}</a>{` that exercise the end user's
experience that you otherwise would need to wait for a quality assurance team
member, or worse, your end-user to discover.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Continuous_integration"
        }}>{`Continuous Integration`}</a>{`
shortens the feedback loop I would otherwise need to wait to manually deploy,
integrate with the latest code, update dependency versions, etc.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.bugsnag.com/"
        }}>{`Production Monitoring`}</a>{` means I get insight into my
code failing without waiting for users to contact support, and for support to
find their way to me.`}</li>
    </ul>
    <p>{`The book advocates use of a
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Kanban_board"
      }}>{`Kanban board`}</a>{` for identifying back
pressure & discovering constraints in your system. If many tasks spend a lot of
time in a specific column, you've got a constraint to address. Generally, I've
found Kanban boards to be very effective if everybody on the team is invested in
its data integrity & onboard with using it as a tool for improving the flow of
work.`}</p>
    <p>{`In many ways, there is a meta "feedback loop" for improving the improvement of
the system. Individual team members know how to shorten loops at their
workstation better than the team does. Individuals know how to shorten feedback
loops between each other better than the team. The team knows how to improve
their interactions & process better than the department. The department leaders,
better than the CEO. Ad Infinitum. In the same way that we ought to shorten
feedback loops from the end-user to business, perhaps we would benefit to
shorten meta feedback loops about the system itself from individual contributors
back to the business.`}</p>
    <h3>{`Learning Culture`}</h3>
    <p>{`The last advocation in the book is to create a culture where constant
experimentation & feedback is encouraged. Intentionally put tension into the
system to reinforce good habits & improve something. Continuous, never-ending
improvement, `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Kaizen"
      }}>{`kaizen`}</a>{`.`}</p>
    <ul>
      <li parentName="ul">{`Safe retrospectives`}</li>
      <li parentName="ul">{`Intentionally creating chaos to exercise crisis response processes`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/cost-of-consensus/"
        }}>{`Distributing technical architecture to mitigate the risk of technical decisions`}</a></li>
    </ul>
    <p>{`This third way is particularly poignant to me as a tempering force against the
first two ways. Often well-intentioned managers trying to improve flow
efficiency & shorten feedback loops institute measures to better understand
their system. Unfortunately, in the words of
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Goodhart%27s_law"
      }}>{`Goodhart`}</a>{`,`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Any statistical regularity will tend to collapse once pressure is placed upon
it for control purposes."`}</p>
    </blockquote>
    <p>{`Or said far more idiomatically, by Marilyn Strathern,`}</p>
    <blockquote>
      <p parentName="blockquote">{`"When a measure becomes a target, it ceases to be a good measure."`}</p>
    </blockquote>
    <p>{`The culture will be an authoritarian culture of hiding mistakes, blame & fear if
people are externally measured & punished. Their measures will look great but
their results won't. A learning culture is a safe culture.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      